.footer {
  display: flex;
  flex-direction: column;
  padding: 1.6rem 0 1.6rem 0;
}

.footer-items {
  display: flex;
  flex-direction: row;
  align-items: center;
  min-height: 3.2rem;
}

.footer-items > :not(:last-child) {
  margin-right: 1.6rem;
}

.footer a {
  text-decoration: none;
}

.footer a:hover {
  color: var(--white);
}
