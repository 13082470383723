:root {
  /* fonts */

  --font-family: "Inter", sans-serif;
  --display: normal normal 400 4.8rem / normal var(--font-family);
  --heading: normal normal bold 2.4rem / normal var(--font-family);
  --description: normal normal 400 2rem / normal var(--font-family);
  --body: normal normal bold 1.5rem / normal var(--font-family);
  --heading-small: normal normal bold 1.8rem / normal var(--font-family);
  --body-small: normal normal bold 1.2rem / normal var(--font-family);
  --heading-large: normal normal bold 3.4rem / normal var(--font-family);
  --paragraph: normal normal normal 1.6rem / normal var(--font-family);
  /* using px because too small to read */
  --footer-item: normal normal bold 12px / normal var(--font-family);
  --legal-boilerplate: italic normal normal 9px / normal var(--font-family);
}

[data-theme="dark"] {
  /*  colors */
  --title: #0b0c0f;
  --surface: #15181e;
  --surface-hover: #000000;
  --background: #0f1115;
  --outline: #38465b;
  --muted: #3f474d;
  --gray: #8fa3b0;
  --white: #ffffff;
  --text-primary: #ffffff;
  --primary: #70a7ff;
  --victory: #50fa7b;
  --defeat: #ff5b5b;
  --online: #3fff20;
  --online-translucent: #3fff20cc;
  --offline: #ff3131;
  --offline-translucent: #ff3131cc;
  --close: #e9102b;
}

.background {
  color: var(--background);
}

.surface {
  color: var(--surface);
}

.surface-hover {
  color: var(--surface-hover);
}

.outline {
  color: var(--outline);
}

.muted {
  color: var(--muted);
}

.gray {
  color: var(--gray);
}

.text-primary {
  color: var(--text-primary);
}

.primary {
  color: var(--primary);
}

.victory {
  color: var(--victory);
}

.defeat {
  color: var(--defeat);
}

.online {
  color: var(--online);
}

.online-translucent {
  color: var(--online-translucent);
}

.offline {
  color: var(--offline);
}

.offline-translucent {
  color: var(--offline-translucent);
}

.white {
  color: var(--white);
}
