::placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: var(--muted);
  opacity: 1; /* Firefox */
}

:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: var(--muted);
}

::-ms-input-placeholder {
  /* Microsoft Edge */
  color: var(--muted);
}

.contact-us-form {
  display: flex;
  flex-direction: column;
}

.contact-us-form > * {
  font: var(--body);
}

.contact-us-form > :not(:last-child) {
  margin-bottom: 1.6rem;
}

input,
textarea {
  padding: 1rem;
  background: var(--surface);
  border: none;
  border-radius: 0.8rem;
  color: var(--text-primary);
  width: 30rem;
  resize: none;
  color-scheme: dark;
}

input:focus,
textarea:focus {
  outline: solid 1px var(--outline);
}

input[type="submit"] {
  background: var(--muted);
  box-sizing: content-box;
}

input[type="submit"]:hover {
  background: var(--primary);
  cursor: pointer;
}

input[type="submit"]:disabled {
  background: var(--muted);
  cursor: not-allowed;
}
