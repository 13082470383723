.navbar {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  align-self: stretch;
  height: 6.4rem;
  min-height: 6.4rem;
}

.navbar-item {
  display: flex;
  align-items: center;
  justify-content: center;
  font: var(--heading);
}

.navbar-item:hover {
  cursor: pointer;
}

.discord-logo-small {
  height: 3.2rem;
}

.logo {
  width: 3.2rem;
}
