::-webkit-scrollbar {
  background-color: var(--background);
  width: 1.4rem;
  height: 1.4rem;
}

::-webkit-scrollbar-thumb {
  background: var(--outline);
  border-radius: 0.7rem;
  border: 0.3rem solid var(--background);
}

::-webkit-scrollbar-corner {
  background: var(--background);
}
