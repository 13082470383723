/* desktop */
html {
  font-size: 10px;
}

.feature-info {
  width: 50%;
}

.feature-image {
  width: 50%;
}

@media (max-width: 1200px) {
  html {
    font-size: 8px;
  }

  .root-container {
    width: 600px;
    min-width: 600px;
    max-width: 600px;
  }

  .contents {
    width: 100%;
  }

  .feature-info {
    width: 100%;
    margin-bottom: 2.4rem;
  }

  .feature-image {
    width: 100%;
  }

  .feature-item-right {
    flex-direction: column-reverse;
  }

  .feature-item-left {
    flex-direction: column-reverse;
  }

  .footer-items {
    flex-direction: column;
    align-items: flex-start;
  }

  .footer-items > * {
    margin-bottom: 1.2rem;
  }
}

@media (max-width: 650px) {
  html {
    font-size: 7px;
  }

  .root-container {
    margin: 0;
    width: inherit;
    min-width: auto;
    max-width: auto;
  }

  .main-header-logo {
    height: 4rem;
    width: auto;
  }
}
