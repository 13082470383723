::selection {
  color: var(--white);
  background: var(--muted);
}

body {
  margin: 0;
  overflow-y: scroll;
  background-color: var(--background);
}

.root-container {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  margin: 0 auto;
  min-width: 108rem;
  width: 108rem;
  max-width: 1080rem;
  padding: 0 1.6rem 0 1.6rem;
}

.privacy-policy,
.contact-us,
.tos,
.faq {
  width: inherit;
  font-family: var(--font-family);
  color: var(--gray);
  flex-grow: 1;
}

.privacy-policy h1,
.contact-us h1,
.tos h1,
.faq h1 {
  color: var(--primary);
}

.contact-us > :not(:last-child) {
  margin-bottom: 1.6rem;
}

.not-found {
  display: flex;
  flex-direction: column;
  width: inherit;
  flex-grow: 1;
  justify-content: center;
}
