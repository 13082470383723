.home {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.main-header {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: calc(100vh - 6.4rem);
}

.main-header > :not(:last-child) {
  margin-bottom: 2.4rem;
}

.beta-tag {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0.8rem;
  background: #3f474d;
  border-radius: 0.8rem;
}

.main-header-title {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.main-header-title > :not(:last-child) {
  margin-right: 1.6rem;
}

.main-header-logo {
  height: 5rem;
  width: auto;
}

.title-description > :not(:last-child) {
  margin-bottom: 0.8rem;
}

.contents {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.contents > * {
  margin-bottom: 28rem;
}

.feature-item {
  display: flex;
}

.feature-item-right {
  flex-direction: row;
}

.feature-item-left {
  flex-direction: row-reverse;
}

.feature-info {
  display: flex;
  flex-direction: column;
  align-items: left;
  justify-content: center;
  width: inherit;
}

.feature-info > :not(:last-child) {
  margin-bottom: 2.4rem;
}

.feature-subtitle {
  text-align: left;
  font: var(--heading-small);
  color: var(--gray);
}

.help-discord {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.help-discord > :not(:last-child) {
  margin-bottom: 2em;
}

.help-discord-text {
  font: var(--heading);
  text-align: center;
}

.discord-logo {
  height: 12rem;
  width: 10.6rem;
}

.download-button {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 1.6rem;
  background-color: var(--primary);
  border-radius: 1.6rem;
  font: var(--heading-small);
  text-decoration: none;
}

.download-button:hover {
  background: var(--gray);
  cursor: pointer;
}
