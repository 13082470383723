.about-us-page {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.about-us-page > :not(:last-child) {
  margin-bottom: 1.6rem;
}

.our-mission {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 10rem 0;
}

.our-mission > :not(:last-child),
.about-us > :not(:last-child) {
  margin-bottom: 1rem;
}

.our-mission-title {
  margin: 0;
}

.our-mission-text {
  text-align: center;
  font: var(--heading-large);
  font-weight: lighter;
  color: var(--gray);
}

.about-us-image {
  width: 100%;
}

.about-us {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  padding: 10rem 0;
}

.about-us-text {
  font: var(--heading-small);
  color: var(--gray);
}
